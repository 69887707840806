import React from 'react'
import styles from '../../MyAccount.module.scss'
import ImageComponent from '../../../ImageComponent/SingleImageComponent'
import { MAGENTO_URL } from '../../../../../userglobals'
import { setSelectedOrder } from '../../../../store/actions'
import Button from '@material-ui/core/Button'
import StatusStepper from './statusStepper'

type orderCardType = {
   order: any
}

const orderCard: React.FC<orderCardType> = ({ order }) => {
   var date = new Date(order?.created_at.replace(/-/g, "/"));
   var day = date.getDate();
   var monthIdx = date.getMonth();
   var year = date.getFullYear();
   var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
   var month = months[monthIdx];

   return (
      <div className={styles.cards} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
         <div>
            <span>N° do Pedido: <strong>{order.entity_id}</strong></span> <br />
            {day < 10 ?
               <span>Realizado em: 0{day}/{month}/{year}</span> :
               <span>Realizado em: {day}/{month}/{year}</span>
            } <br /> <br />
            <div style={{ width: '150px', marginLeft: 'auto', marginRight: 'auto' }}>
               <ImageComponent altImg={order.items[0].name} imgUrl={MAGENTO_URL + "media/catalog/product" + order.items[0].productData.image} _class={styles.productImg} />
               <p style={{ fontSize: '12px', lineHeight: 'normal' }}>{order.items[0].name}</p>
            </div>
         </div>
         <Button variant="contained" color="secondary" onClick={() => setSelectedOrder(order.entity_id)} style={{ width: '40%', color: '#e8927c', boxShadow: '0 3px 3px 0 rgba(89, 89, 89, 0.1)', fontWeight: 'bold' }}>
            Ver pedido
            </Button>
         <div style={{padding: '20px 0 15px', width: '100%'}}>
            <StatusStepper order={order} />
         </div>
      </div>
   )
}

export default orderCard
