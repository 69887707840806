import React from 'react'
import Menu from '../../components/menu'
import { Button } from '@material-ui/core'
import StatusStepper from './statusStepper'
import styles from '../../MyAccount.module.scss'
import customOptions from 'src/components/Products/components/customOptions'

type orderType = {
   order: any
}

const order: React.FC<orderType> = ({ order }) => {
   const envio = (order !== undefined && order.status_histories.length > 0) ? JSON.parse(order?.status_histories.find((x: any) => x.status === "pendente").comment) : "";
   console.log(order);

   var date = new Date(order?.created_at.replace(/-/g, "/"));
   var day = date.getDate();
   var monthIdx = date.getMonth();
   var year = date.getFullYear();
   var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
   var month = months[monthIdx];

   return (
      <div style={{ width: '100%', backgroundColor: '#fff' }}>
         <div style={{ textAlign: 'center', borderBottom: '2px solid #f8f7f6' }}>
            <div style={{ padding: '15px' }}>
               <span>N° do Pedido: <strong>{order?.entity_id}</strong></span>
               <br></br>
               {day < 10 ?
                  <span>Realizado em: 0{day}/{month}/{year}</span> :
                  <span>Realizado em: {day}/{month}/{year}</span>
               }
            </div>
            <div style={{ padding: '10px 0' }}>
               <StatusStepper order={order} />
            </div>
            <div style={{ padding: '15px', display: 'flex', justifyContent: 'space-between' }}>
               <Button variant='outlined' color='primary' size='small' style={{ width: '49%', fontSize: '12px' }}>
                  Pedir novamente
                  </Button>
               <Button variant='outlined' color='primary' size='small' style={{ width: '49%', fontSize: '12px' }}>
                  Gerar boleto
                  </Button>
            </div>
         </div>
         <div>
            <p style={{ padding: '0 15px' }} className={styles.subtitles}>Itens Pedidos</p>
            <div style={{ display: 'flex' }}>
               <div style={{ width: '13%', backgroundColor: '#FBECE8', borderRight: '2px solid #f8f7f6', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '13px', padding: '5px 0' }}>SKU</div>
               <div style={{ width: '41%', backgroundColor: '#FBECE8', borderRight: '2px solid #f8f7f6', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '13px', padding: '5px 0' }}>PRODUTO</div>
               <div style={{ width: '17%', backgroundColor: '#FBECE8', borderRight: '2px solid #f8f7f6', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '13px', padding: '5px 0' }}>PREÇO</div>
               <div style={{ width: '12%', backgroundColor: '#FBECE8', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '13px', padding: '5px 0' }}>QTDE</div>
               <div style={{ width: '17%', backgroundColor: '#FBECE8', borderLeft: '2px solid #f8f7f6', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '13px', padding: '5px 0', textAlign: 'center' }}>SUB<br />TOTAL</div>
            </div>
            {order?.items.map((item: any, index: any) => {
               if (item.product_type !== "configurable") {
                  return (
                     <div key={index} style={{ display: 'flex', borderBottom: '2px solid #f8f7f6' }}>
                        <div style={{ width: '13%', justifyContent: 'center', borderRight: '2px solid #f8f7f6', padding: '5px', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
                           <span style={{ wordBreak: 'break-all' }}>{item.sku}</span>
                        </div>
                        <div style={{ width: '41%', justifyContent: 'center', borderRight: '2px solid #f8f7f6', padding: '5px', alignItems: 'center', display: 'flex', textAlign: 'center', flexWrap: 'wrap' }}>
                           <div style={{ width: '100%' }}>
                              <p style={{ fontSize: '13px', margin: 0 }}>{item.name}</p>
                           </div>
                           {("parent_item" in item) &&
                              <div style={{ backgroundColor: '#fbece8', padding: '3px 10px', borderRadius: '5px', height: 'fit-content', textAlign: 'start' }}>
                                 {item?.parent_item?.product_option?.extension_attributes?.custom_options.map((customOption: any, index: any) => (
                                    < div key={index} style={{ fontSize: '12px' }}>
                                       {customOption?.title}: <strong>{customOption?.option_value}</strong>
                                    </div>
                                 ))}
                              </div>
                           }
                           {("product_option" in item) &&
                              <div style={{ backgroundColor: '#fbece8', padding: '3px 10px', borderRadius: '5px', height: 'fit-content', textAlign: 'start' }}>
                                 {item?.product_option?.extension_attributes?.custom_options.map((customOption: any, index: any) => (
                                    <div key={index} style={{ fontSize: '12px' }}>
                                       {customOption.title}: <strong>{customOption?.option_value}</strong>
                                    </div>
                                 ))}
                              </div>
                           }
                        </div>
                        <div style={{ width: '17%', justifyContent: 'center', borderRight: '2px solid #f8f7f6', padding: '5px', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
                           <span style={{ wordBreak: 'break-word', fontSize: '13px' }}>R$ {item.price.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span>
                        </div>
                        <div style={{ width: '12%', justifyContent: 'center', padding: '5px', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
                           {item.qty_ordered}
                        </div>
                        <div style={{ width: '17%', justifyContent: 'center', borderLeft: '2px solid #f8f7f6', padding: '5px', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
                           <span style={{ wordBreak: 'break-word', fontSize: '13px' }}>R$ {(item.qty_ordered * item.price).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span>
                        </div>
                     </div>)
               }
            })}
            <div style={{ display: 'flex', borderBottom: '2px solid #f8f7f6' }}>
               <div style={{ width: '83%', textAlign: 'end', padding: '0 20px 0 0', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>Subtotal</div>
               <div style={{ width: '17%', textAlign: 'center', borderLeft: '2px solid #f8f7f6', padding: '5px' }}><span style={{ wordBreak: 'break-word', fontSize: '13px' }}>R$ {order?.subtotal.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span></div>
            </div>
            <div style={{ display: 'flex', borderBottom: '2px solid #f8f7f6' }}>
               <div style={{ width: '83%', textAlign: 'end', padding: '0 20px 0 0', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>Envio e manuseio</div>
               <div style={{ width: '17%', textAlign: 'center', borderLeft: '2px solid #f8f7f6', padding: '5px' }}><span style={{ wordBreak: 'break-word', fontSize: '13px' }}>R$ {envio?.shippingPrice}</span></div>
            </div>
            <div style={{ display: 'flex', borderBottom: '2px solid #f8f7f6', backgroundColor: '#fbece8' }}>
               <div style={{ width: '83%', textAlign: 'end', padding: '0 20px 0 0', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontSize: '13px' }}>TOTAL</div>
               <div style={{ width: '17%', textAlign: 'center', borderLeft: '2px solid #f8f7f6', padding: '5px', fontWeight: 'bold' }}><span style={{ wordBreak: 'break-word', fontSize: '13px' }}>R$ {(order?.subtotal+(+(envio?.shippingPrice?.replace(',','.')))).toFixed(2).replace('.',',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span></div>
            </div>
         </div>
         <div>
            <div style={{ borderBottom: '2px solid #f8f7f6', padding: '15px' }}>
               <span className={styles.subtitles}>Pagamento</span>
               <br />
               {order?.payment.method === "billet" ? <strong>Boleto Bancário</strong> :
                  order?.payment.method === "creditcard" ? <strong>Cartão de Crédito</strong> : <strong>Outro Pagamento</strong>
               }
               <br />
               <span>Status do Pagamento: {
                  order?.status === "pending" ? "Pendente" :
                     order?.status === "canceled" ? "Cancelado" : "Confirmado"}</span>
            </div>
            <div style={{ borderBottom: '2px solid #f8f7f6', padding: '15px' }}>
               <span className={styles.subtitles}>Envio</span> <br />
               {
                  envio !== "" && envio !== null ?
                     <span>Correios - {envio?.shippingService} - {envio?.shippingTime} dias úteis após envio.</span> :
                     <span>Correios</span>
               }
            </div>
            <div style={{ padding: '15px', borderBottom: '2px solid #f8f7f6' }}>
               <span className={styles.subtitles}>Endereço de Cobrança</span>
               <br />
               <span>{`${order?.billing_address.firstname} ${order?.billing_address.lastname}`}</span> <br />
               {
                  order?.billing_address.street[3] ?
                     <span>{`${order?.billing_address.street[0]}, ${order?.billing_address.street[1]}, ${order?.billing_address.street[3]}, ${order?.billing_address.street[2]}`}</span> :
                     <span>{`${order?.billing_address.street[0]}, ${order?.billing_address.street[1]}, ${order?.billing_address.street[2]}`}</span>
               }
               <br />
               <span>{`${order?.billing_address.city}, ${order?.billing_address.region}`}</span> <br />
               <span>{`${order?.billing_address.postcode}`}</span> <br />
               <span>{`${order?.billing_address.telephone}`}</span> <br />
            </div>
            <div style={{ padding: '15px', borderBottom: '2px solid #f8f7f6' }}>
               <span className={styles.subtitles}>Endereço de Entrega</span>
               <br />
               <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.firstname} ${order?.extension_attributes.shipping_assignments[0].shipping.address.lastname}`}</span> <br />
               {
                  order?.extension_attributes.shipping_assignments[0].shipping.address.street[3] ?
                     <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.street[0]}, ${order?.extension_attributes.shipping_assignments[0].shipping.address.street[1]}, ${order?.extension_attributes.shipping_assignments[0].shipping.address.street[3]}, ${order?.extension_attributes.shipping_assignments[0].shipping.address.street[2]}`}</span> :
                     <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.street[0]}, ${order?.extension_attributes.shipping_assignments[0].shipping.address.street[1]}, ${order?.extension_attributes.shipping_assignments[0].shipping.address.street[2]}`}</span>
               }
               <br />
               <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.city}, ${order?.extension_attributes.shipping_assignments[0].shipping.address.region}`}</span> <br />
               <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.postcode}`}</span> <br />
               <span>{`${order?.extension_attributes.shipping_assignments[0].shipping.address.telephone}`}</span> <br />
            </div>
         </div>
      </div >
   )
}

export default order
