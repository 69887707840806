import React, { useEffect, useState } from 'react'
import styles from '../MyAccount.module.scss'
import Menu from '../components/menu'
import { getWishList, setLoadingFalse, setLoadingTrue } from '../../../store/actions'
import ProductCard from './components/productCard'
import { useSelector } from 'react-redux'

type WishlistType = {

}

const Wishlist: React.FC<WishlistType> = () => {
  const userAuth = useSelector((state: any) => state.userAuth);
  const [wishlist, setWishlist] = useState(undefined)

  useEffect(() => {
    async function getData() {
      await setLoadingTrue()
      getWishList({ ...userAuth, wishlist: [] }).then(response => {
        setWishlist(response?.reverse())
        setLoadingFalse()
      })
    }
    getData();
  }, []);

  const handleDeleteFromWishlist = (id: string) => {
    console.log(id)
    const copyWishlist = JSON.parse(JSON.stringify(wishlist));
    const index = copyWishlist.findIndex((x: any) => x.product_id == id);
    console.log(index)
    copyWishlist.splice(index, 1);
    console.log(copyWishlist)
    setWishlist(copyWishlist)
  }

  return (
    <div>
      <Menu active={6} />
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {wishlist !== undefined && wishlist.length > 0 ?
          wishlist.map((product: any, idx: number) => {
            return (
              <ProductCard key={idx} product={product} handleDeleteFromWishlist={handleDeleteFromWishlist} />
            )
          }) :
          <div style={{backgroundColor: '#fff', padding: '15px', minHeight: '80vh'}}>
            <p className={styles.primaryColor}>Você ainda não adicionou nenhum produto à sua lista de desejos.</p>
          </div>
        }
      </div>
    </div>
  )
}

export default Wishlist
