import React from 'react'
import styles from '../../MyAccount.module.scss'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import { Link, navigate } from 'gatsby'
import ImageComponent from '../../../ImageComponent/SingleImageComponent'
import { MAGENTO_URL } from '../../../../../userglobals'
import StatusStepper from '../../Orders/components/statusStepper'
import { setSelectedOrder } from '../../../../store/actions'

type orderType = {
   order: any
}

const order: React.FC<orderType> = ({ order }) => {
   console.log(order)
   const userData = useSelector((state: any) => state.userData);
   var day = 0;
   var month = "";
   var year = 0;

   if (order !== undefined && order?.created_at !== undefined) {
      var date = new Date(order?.created_at.replace(/-/g, "/"));
      day = date.getDate();
      var monthIdx = date.getMonth();
      year = date.getFullYear();
      var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
      month = months[monthIdx];
   }
   if (order !== undefined) {

      console.log(order)
      return (
         <div className={styles.divsMain}>
            <span className={styles.subtitles}>Último pedido</span> &nbsp;&nbsp;&nbsp;
            <Button
               disableTouchRipple
               component={Link}
               to={'/minha-conta/pedidos/'}
               onClick={() => setSelectedOrder(null)}
               variant='text'
               color='primary'
               size='small'
               className={styles.padding0}
               classes={{ label: styles.labelButton }}
            >
               Ver todos
         </Button>

            {
               order !== null ?
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingTop: '5px', textAlign: 'center' }}>
                     <div>
                        <span>N° do Pedido: <strong>{order?.entity_id}</strong></span> <br />
                        {day < 10 ?
                           <span>Realizado em: 0{day}/{month}/{year}</span> :
                           <span>Realizado em: {day}/{month}/{year}</span>
                        } <br /> <br />
                        <div style={{ width: '150px', marginLeft: 'auto', marginRight: 'auto' }}>
                           <ImageComponent altImg={order?.items[0].name} imgUrl={MAGENTO_URL + "media/catalog/product" + order?.items[0].productData.image} _class={styles.productImg} />
                           <p style={{ fontSize: '12px', lineHeight: 'normal' }}>{order?.items[0].name}</p>
                        </div>
                     </div>
                     <Button variant="contained" color="secondary" onClick={() => { setSelectedOrder(order?.entity_id); navigate('/minha-conta/pedidos/') }} style={{ width: '55%', color: '#e8927c', boxShadow: '0 3px 3px 0 rgba(89, 89, 89, 0.2)', fontWeight: 'bold' }}>
                        Ver pedido
                        </Button>
                     <div style={{ padding: '20px 0 0' }}>
                        <StatusStepper order={order} />
                     </div>
                  </div> :
                  <div style={{ paddingTop: '5px' }}>
                     Você ainda não fez nenhum pedido.
                     </div>
            }
         </div>

      )
   } else {
      return (<div>Carregando Pedido...</div>)
   }
}

export default order