import React from 'react'
import styles from './DrawerChangePassword.module.scss'
import { useSelector } from 'react-redux'
import Drawer from "@material-ui/core/Drawer"
import { handleChangePassword, handleSaveUserData, toggleChangePassword } from '../../../store/actions'
import Button from '@material-ui/core/Button'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import Textfield from '@material-ui/core/TextField'

type DrawerChangePasswordType = {
   userAuth: any
   passwordState: {
      currentPassword: string;
      newPassword: string;
      confirmPassword: string;
   }
   handleChangePasswordState: (label: any, value: any) => void
}

const DrawerChangePassword: React.FC<DrawerChangePasswordType> = ({ passwordState, handleChangePasswordState, userAuth }) => {
   const openChangePassword = useSelector((state: any) => state.myAccount.openChangePassword);

   return (
      <Drawer
         anchor={"left"}
         open={openChangePassword}
         onClose={() => toggleChangePassword(false)}
         data-testid="drawer-category"
         classes={{
            paper: styles.mainContainer,
         }}
      >
         <div className={styles.drawerPasswordMain}>
            <div className={styles.gridMenu}>
               <div className={styles.closeButton}></div>
               <div className={styles.menuPrincipal}>
                  <span>MENU</span>
               </div>
               <div className={styles.closeButton}>
                  <Button
                     onClick={() => toggleChangePassword(false)}
                     disableTouchRipple
                     color="primary"
                     style={{ minWidth: "20px", height: "20px", padding: 0 }}
                  >
                     <CloseRoundedIcon fontSize="small" />
                  </Button>
               </div>
            </div>

            <div className={styles.passwordContentMain}>
               <div className={styles.gridData}>
                  <div>
                     <Textfield
                        className="textfieldFormAddresses"
                        placeholder="Senha atual"
                        variant="outlined"
                        size="small"
                        type="password"
                        value={passwordState.currentPassword}
                        onChange={(e: any) => handleChangePasswordState('currentPassword', e.target.value)}
                        fullWidth
                        style={{ margin: '5px 0' }}
                     />
                     <Textfield
                        className="textfieldFormAddresses"
                        placeholder="Nova senha"
                        variant="outlined"
                        size="small"
                        type="password"
                        value={passwordState.newPassword}
                        fullWidth
                        onChange={(e: any) => handleChangePasswordState('newPassword', e.target.value)}
                        style={{ margin: '5px 0' }}
                     />
                     <Textfield
                        className="textfieldFormAddresses"
                        placeholder="Confirmar nova senha"
                        variant="outlined"
                        size="small"
                        type="password"
                        value={passwordState.confirmPassword}
                        fullWidth
                        onChange={(e: any) => handleChangePasswordState('confirmPassword', e.target.value)}
                        style={{ margin: '5px 0' }}
                     />
                     <div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '5px 0' }}>
                           <Button variant="outlined" color="primary" style={{ width: '40%', fontWeight: 'bold', textTransform: 'none' }} onClick={() => { handleChangePassword(userAuth, passwordState.currentPassword, passwordState.newPassword, passwordState.confirmPassword) }}>
                              Salvar
                        </Button>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '5px 0' }}>
                           <Button variant="text" color="primary" style={{ textTransform: 'none' }} onClick={() => toggleChangePassword(false)}>
                              Cancelar
                        </Button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div style={{height: '60px'}} />
         </div>
      </Drawer>
   )
}

export default DrawerChangePassword
