import React from 'react'
import styles from '../../MyAccount.module.scss'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import { Link, navigate } from 'gatsby'

type informationType = {
   toggleChangePassword: (bool: boolean) => void
}

const information: React.FC<informationType> = ({ toggleChangePassword }) => {
   const userData = useSelector((state: any) => state.userData)
   return (
      <div className={styles.divsMain}>
         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={styles.subtitles}>Informações</span>
            <Button
               disableTouchRipple
               component={Link}
               to={'/minha-conta/dados/'}
               onClick={() => toggleChangePassword(false)}
               variant='text'
               color='primary'
               size='small'
               className={styles.padding0}
               classes={{ label: styles.labelButton }}
            >
               Editar
         </Button>
         </div>
         <div style={{ paddingTop: '5px' }}>
            <span>{userData.firstname} {userData.lastname}</span> <br />
            <span>{userData.email}</span>
         </div>
         <Button
            disableTouchRipple
            onClick={() => { toggleChangePassword(true); navigate('/minha-conta/dados/') }}
            variant='text'
            color='primary'
            size='small'
            className={styles.buttonAlterarSenha}
            classes={{ label: styles.labelButton }}
         >
            Alterar senha
         </Button>
      </div>
   )
}

export default information