import React from 'react'
import styles from '../../MyAccount.module.scss'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import { Link } from 'gatsby'


type wishlistType = {

}

const wishlist: React.FC<wishlistType> = () => {
   return (
      <div className={styles.divsMain}>
         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={styles.subtitles}>Lista de desejos</span>
            <Button
               disableTouchRipple
               component={Link}
               to={'/minha-conta/lista-de-desejos/'}
               variant='text'
               color='primary'
               size='small'
               className={styles.padding0}
               classes={{ label: styles.labelButton }}
            >
               Ver todos
         </Button>
         </div>
         <div className={styles.paddingTop5} style={{ textAlign: 'justify' }}>
            <span>Confira todos os produtos que você já favoritou.</span>
         </div>
      </div>
   )
}

export default wishlist
