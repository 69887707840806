import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import styles from '../../MyAccount.module.scss'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'

type statusStepperType = {
   order: any
}

const statusStepper: React.FC<statusStepperType> = ({ order }) => {

   switch (order?.status) {
      case 'canceled': {
         return (
            <div style={{ textTransform: 'uppercase', fontWeight: 'bold', color: '#cb242c', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <CancelRoundedIcon fontSize='small' />
               &nbsp;
               Cancelado
            </div>
         )
      }
      case 'pendente':
      case 'pagamento_confirmado':
      case 'em_separacao':
      case 'pedido_enviado': {
         return (
            <Stepper color='primary' activeStep={
               order?.status === 'pendente' ? 0 :
                  order?.status === 'pagamento_confirmado' ? 1 :
                     order?.status === 'em_separacao' ? 2 :
                        order?.status === 'pedido_enviado' ? 3 : 0
            } connector={<StepConnector />} alternativeLabel classes={{ root: styles.stepper }}>
               <Step color='primary'>
                  <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                     Pagamento pendente
                     </StepLabel>
               </Step>
               <Step color='primary'>
                  <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                     Pagamento confirmado
                  </StepLabel>
               </Step>
               <Step color='primary'>
                  <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                     Em separação
                  </StepLabel>
               </Step>
               <Step color='primary'>
                  <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                     Pedido enviado
                  </StepLabel>
               </Step>
            </Stepper>
         )
      }
      case 'troca_solicitada':
      case 'troca_enviada': {
         return (
            <Stepper color='primary' activeStep={
               order?.status === 'troca_solicitada' ? 0 :
                  order?.status === 'troca_enviada' ? 1 : 0
            } connector={<StepConnector />} alternativeLabel classes={{ root: styles.stepper }}>
               <Step color='primary'>
                  <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                     Troca solicitada
                     </StepLabel>
               </Step>
               <Step color='primary'>
                  <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                     Troca enviada
                  </StepLabel>
               </Step>
            </Stepper>
         )
      }
      case 'pedido_extraviado': {
         return (
            <div style={{ textTransform: 'uppercase', fontWeight: 'bold', color: '#cb242c', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <CancelRoundedIcon fontSize='small' />
               &nbsp;
               Pedido extraviado
            </div>
         )
      }
      case 'devolucao_solicitada':
      case 'pedido_devolvido': {
         return (
            <Stepper color='primary' activeStep={
               order?.status === 'devolucao_solicitada' ? 0 :
                  order?.status === 'pedido_devolvido' ? 1 : 0
            } connector={<StepConnector />} alternativeLabel classes={{ root: styles.stepper }}>
               <Step color='primary'>
                  <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                     Devolução solicitada
                     </StepLabel>
               </Step>
               <Step color='primary'>
                  <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                     Devolução confirmada
                  </StepLabel>
               </Step>
            </Stepper>
         )
      }
      case 'devolvido_transportador':
      case 'pedido_reenviado': {
         return (
            <Stepper color='primary' activeStep={
               order?.status === 'devolvido_transportador' ? 0 :
                  order?.status === 'pedido_reenviado' ? 1 : 0
            } connector={<StepConnector />} alternativeLabel classes={{ root: styles.stepper }}>
               <Step color='primary'>
                  <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                     Devolvido pelo transportador
                     </StepLabel>
               </Step>
               <Step color='primary'>
                  <StepLabel StepIconProps={{ classes: { completed: styles.completedIcon } }} classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                     Pedido reenviado
                  </StepLabel>
               </Step>
            </Stepper>
         )
      }
      default: {
         return null;
      }
   }
}

export default statusStepper
