import React from 'react'
import styles from '../../MyAccount.module.scss'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import { Link, navigate } from 'gatsby'
import ClassNames from 'classnames/bind'

let cx = ClassNames.bind(styles);

type addressesType = {
   toggleEditAddress: (bool: boolean) => void
}

const addresses: React.FC<addressesType> = ({ toggleEditAddress }) => {
   const userData = useSelector((state: any) => state.userData)

   const defaultBilling = userData.addresses.find((x: { default_billing: boolean }) => x.default_billing)
   const defaultShipping = userData.addresses.find((x: { default_shipping: boolean }) => x.default_shipping)
   // console.log(userData)

   return (
      <div className={styles.divsMain}>
         <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span className={styles.subtitles}>Endereços</span>
         <Button
               disableTouchRipple
               component={Link}
               to={'/minha-conta/enderecos/'}
               onClick={() => toggleEditAddress(false)}
               variant='text'
               color='primary'
               size='small'
               className={styles.padding0}
               classes={{ label: styles.labelButton }}
            >
               Gerenciar
         </Button>
         </div>
         <Grid container style={{ paddingTop: '5px' }}>
            <Grid item xs={12} md={6} className={styles.gridMainBilling}>
               <span className={styles.subtitleBlackBilling}>Endereço padrão de cobrança</span>
               <div className={styles.divMainBilling}>
                  {!defaultBilling ?
                     <>
                        {userData.addresses[0].firstname} {userData.addresses[0].lastname} <br />
                        {userData.addresses[0].street[0]}, {userData.addresses[0].street[1]}, {userData.addresses[0].street[2]} <br />
                        {userData.addresses[0].city}, {userData.addresses[0].region.region_code} <br />
                        CEP: {userData.addresses[0].postcode} <br />
                        {userData.addresses[0].telephone.replace(/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/, "($1) $2-$3")}
                     </> :
                     <>
                        {defaultBilling.firstname} {defaultBilling.lastname} <br />
                        {defaultBilling.street[0]}, {defaultBilling.street[1]}, {defaultBilling.street[2]} <br />
                        {defaultBilling.city}, {defaultBilling.region.region_code} <br />
                        CEP: {defaultBilling.postcode} <br />
                        {defaultBilling.telephone.replace(/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/, "($1) $2-$3")}
                     </>
                  }

               </div>
            </Grid>
            <Grid item xs={12} md={6} className={styles.gridMainShipping}>
               <span className={styles.subtitleBlackShipping}>Endereço padrão de envio</span>
               <div className={styles.divMainShipping}>
                  {defaultShipping.firstname} {defaultShipping.lastname} <br />
                  {defaultShipping.street[0]}, {defaultShipping.street[1]}, {defaultShipping.street[2]} <br />
                  {defaultShipping.city}, {defaultShipping.region.region_code} <br />
                  CEP: {defaultShipping.postcode} <br />
                  {defaultShipping.telephone.replace(/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/, "($1) $2-$3")}
               </div>
            </Grid>
         </Grid>
      </div>
   )
}

export default addresses
