import React from 'react'
import styles from '../MyAccount.module.scss'
import Menu from '../components/menu'

type RatingsType = {

}

const Ratings: React.FC<RatingsType> = () => {
  return (
    <div style={{backgroundColor: '#fff', minHeight: '90vh'}}>
      <Menu active={5} />
      <div style={{ width: '100%', padding: '15px' }}>
        <p className={styles.primaryColor}>Você ainda não enviou nenhuma avaliação.</p>
      </div>
    </div>
  )
}

export default Ratings
