import React from 'react'
import styles from './DrawerEditAddress.module.scss'
import { useSelector } from 'react-redux'
import Drawer from "@material-ui/core/Drawer"
import Button from '@material-ui/core/Button'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import regions from '../../../data/regions.json'

type DrawerEditAddressType = {
   openEditAddress: any
   editAddress: any
   handleEditAddress: any
   saveEditAddress: any
   handleOpenEditAddress: any
   savingIn: any
}

const DrawerEditAddress: React.FC<DrawerEditAddressType> = ({ openEditAddress, editAddress, handleEditAddress, saveEditAddress, handleOpenEditAddress, savingIn }) => {
   console.log((Object.keys(editAddress).length !== 0))
   return (
      <Drawer
         anchor={"left"}
         open={openEditAddress}
         onClose={() => handleOpenEditAddress(savingIn)}
         data-testid="drawer-category"
         classes={{
            paper: styles.mainContainer,
         }}
      >
         <div className={styles.drawerEditAddressMain}>
            <div className={styles.gridMenu}>
               <div className={styles.closeButton}></div>
               <div className={styles.menuPrincipal}>
                  <span>EDITAR ENDEREÇO</span>
               </div>
               <div className={styles.closeButton}>
                  <Button
                     onClick={() => { }}
                     disableTouchRipple
                     color="primary"
                     style={{ minWidth: "20px", height: "20px", padding: 0 }}
                  >
                     <CloseRoundedIcon fontSize="small" />
                  </Button>
               </div>
            </div>

            <div className={styles.editAddressContentMain}>
               {
                  editAddress &&
                  <div className={styles.gridData}>
                     {
                        Object.keys(editAddress).length !== 0 &&
                        <div>
                           <TextField
                              classes={{ root: "textfieldFormAddresses" }}
                              placeholder={'Nome'}
                              inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                              variant="outlined"
                              size="small"
                              type="text"
                              value={editAddress.firstname}
                              onChange={(e) => handleEditAddress('firstname', e.target.value)}
                              required
                              fullWidth
                              style={{ margin: '5px 0' }}
                           />
                           <TextField
                              classes={{ root: "textfieldFormAddresses" }}
                              placeholder={'Sobrenome'}
                              inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                              variant="outlined"
                              size="small"
                              type="text"
                              value={editAddress.lastname}
                              onChange={(e) => handleEditAddress('lastname', e.target.value)}
                              required
                              fullWidth
                              style={{ margin: '5px 0' }}
                           />
                           <InputMask
                              mask="99999-999"
                              onChange={(e) => handleEditAddress('postcode', e.target.value)}

                              value={editAddress.postcode}
                              disabled={false}
                              maskChar=" ">
                              {
                                 () =>
                                    <TextField
                                       classes={{ root: "textfieldFormAddresses" }}
                                       id="postcode"
                                       placeholder={'CEP'}
                                       inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                                       variant="outlined"
                                       size="small"
                                       type="text"
                                       required
                                       fullWidth
                                       style={{ margin: '5px 0' }}
                                    />
                              }
                           </InputMask>
                           <TextField
                              classes={{ root: "textfieldFormAddresses" }}
                              id="street"
                              placeholder={'Rua'}
                              inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                              variant="outlined"
                              size="small"
                              value={editAddress.street[0]}
                              type="text"
                              onChange={(e) => handleEditAddress('street', e.target.value)}
                              required
                              style={{ margin: '5px 0', width: '74%' }}
                           />
                           <TextField
                              classes={{ root: "textfieldFormAddresses" }}
                              id="number"
                              placeholder={'N°'}
                              inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                              variant="outlined"
                              size="small"
                              type="text"
                              required
                              style={{ width: '24%', margin: '5px 0', marginLeft: '2%' }}
                              value={editAddress.street[1]}
                              onChange={(e) => handleEditAddress('number', e.target.value)}
                           />
                           <TextField
                              classes={{ root: "textfieldFormAddresses" }}
                              placeholder={'Complemento'}
                              inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                              variant="outlined"
                              size="small"
                              value={editAddress.street[3] !== undefined ? editAddress.street[3] : ""}
                              onChange={(e) => handleEditAddress('complement', e.target.value)}
                              type="text"
                              required
                              fullWidth
                              style={{ margin: '5px 0' }}
                           />
                           <TextField
                              classes={{ root: "textfieldFormAddresses" }}
                              id="block"
                              value={editAddress.street[2]}
                              placeholder={'Bairro'}
                              inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                              variant="outlined"
                              size="small"
                              type="text"
                              onChange={(e) => handleEditAddress('block', e.target.value)}
                              required
                              fullWidth
                              style={{ margin: '5px 0' }}
                           />
                           <TextField
                              classes={{ root: "textfieldFormAddresses" }}
                              id="city"
                              placeholder={'Cidade'}
                              inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                              value={editAddress.city}
                              variant="outlined"
                              size="small"
                              type="text"
                              onChange={(e) => handleEditAddress('city', e.target.value)}
                              required
                              fullWidth
                              style={{ margin: '5px 0' }}
                           />
                           <TextField
                              select
                              classes={{ root: "textfieldFormAddresses" }}
                              size="small"
                              style={{ margin: '5px 0' }}
                              value={editAddress.region.region_id.toString()}
                              onChange={(e, child: any) => { handleEditAddress('region', child.props) }}
                              SelectProps={{
                                 MenuProps: {
                                    style: { marginTop: '40px', maxHeight: '60vh' }
                                 }
                              }}
                              variant="outlined"
                              fullWidth
                           >
                              {regions.map((region) => {
                                 return (
                                    <MenuItem
                                       id={region.code}
                                       key={region.id}
                                       classes={{ selected: styles.selectActive }}
                                       className={styles.selectOptions}
                                       value={region.id}>{region.name}
                                    </MenuItem>
                                 )
                              })}
                           </TextField>
                           <InputMask
                              mask="(99) 9 9999-9999"
                              onChange={(e) => handleEditAddress('telephone', e.target.value)}
                              value={editAddress.telephone}
                              disabled={false}
                              maskChar=" ">
                              {
                                 () =>
                                    <TextField
                                       classes={{ root: "textfieldFormAddresses" }}
                                       id="telephone"
                                       placeholder={'Telefone'}
                                       inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                                       variant="outlined"
                                       size="small"
                                       type="text"
                                       required
                                       fullWidth
                                       style={{ margin: '5px 0' }}
                                    />
                              }
                           </InputMask>
                        </div>
                     }
                     <div>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', margin: '5px 0'}}>
                           <Button onClick={() => saveEditAddress()} variant='outlined' color='primary' style={{ textTransform: 'none', width: '50%', fontWeight: 'bold', boxShadow: 'none' }}>
                              Confirmar
                        </Button>
                        </div>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', margin: '5px 0'}}>
                           <Button onClick={() => handleOpenEditAddress(savingIn)} variant='text' color='primary' style={{ textTransform: 'none' }}>
                              Cancelar
                        </Button></div>
                     </div>
                  </div>
               }
            </div>

            <div style={{height: '60px'}} />
         </div>
      </Drawer>
   )
}

export default DrawerEditAddress
