import React from 'react'
import Button from '@material-ui/core/Button'
import { Link } from 'gatsby'
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded'

type menuType = {
   active: number
   selectedOrder?: any
}

const menu: React.FC<menuType> = ({ active, selectedOrder }) => {
   return (
      active === 1 ? null :
         <div style={{ backgroundColor: '#fff', borderBottom: '2px solid #f8f7f6', padding: '15px', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button component={Link} to={'/minha-conta/'} style={{ padding: 0, fontSize: '12px' }}>
               <KeyboardArrowLeftRoundedIcon fontSize='small' style={{ padding: 0 }} />
                  VOLTAR
               </Button>
            {active === 2 ? <span>MEUS DADOS</span> :
               active === 3 ? <span>MEUS ENDEREÇOS</span> :
                  active === 4 && selectedOrder === null ? <span>MEUS PEDIDOS</span> :
                     active === 4 && selectedOrder !== null ? <span>PEDIDO</span> :
                        active === 5 ? <span>AVALIAÇÕES</span> :
                           active === 6 ? <span>LISTA DE DESEJOS</span> :
                              active === 7 ? <span>NEWSLETTER</span> :
                                 null}
            <div style={{ width: '60px' }} />
         </div>
   )
}

export default menu
