import React from 'react'
import styles from '../../MyAccount.module.scss'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import { Link } from 'gatsby'

type ratingsType = {

}

const ratings: React.FC<ratingsType> = () => {
   const userData = useSelector((state: any) => state.userData)
   return (
      <div className={styles.divsMain}>
         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span className={styles.subtitles}>Avaliações</span> &nbsp;&nbsp;&nbsp;
         <Button
               disableTouchRipple
               component={Link}
               to={'/minha-conta/avaliacoes/'}
               variant='text'
               color='primary'
               size='small'
               className={styles.padding0}
               classes={{ label: styles.labelSeeRatings }}
            >
               Ver todas
         </Button>
         </div>
         <div className={styles.paddingTop5}>
            <span>Você não avaliou nenhum produto ainda.</span>
         </div>
      </div>
   )
}

export default ratings