import React from 'react'
import styles from '../../MyAccount.module.scss'
import { useSelector } from 'react-redux'

type greetingType = {

}

const greeting: React.FC<greetingType> = () => {
   const userData = useSelector((state: any) => state.userData)
   return (
      <div className={styles.divGreetingMain}>
         <p className={styles.hello}>Olá, {userData.firstname} {userData.lastname}</p>
         <span style={{fontSize: '13.5px'}} >No painel da sua conta você poderá ver um resumo de suas atividades recentes e atualizar as informações da sua conta.</span>
      </div>
   )
}

export default greeting