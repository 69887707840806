import React, { useState, useEffect } from 'react'
import styles from '../MyAccount.module.scss'
import { toggleChangePassword, toggleEditAddress, setLoadingTrue, getCustomerLastOrder, setLoadingFalse } from '../../../store/actions'
import Menu from '../components/menu'
import {
  Greeting,
  Addresses,
  Information,
  Newsletter,
  Order,
  Ratings,
  Wishlist
} from './components'
import { useSelector } from 'react-redux'

type MainType = {

}

const Main: React.FC<MainType> = () => {
  const userAuth = useSelector((state: any) => state.userAuth)
  const [order, setOrder] = useState(undefined);

  useEffect(() => {

    async function getData() {
      await setLoadingTrue()
      getCustomerLastOrder(userAuth).then(response => {
        console.log(response)
        setOrder(response)

        setLoadingFalse()
      })
    }
    getData();

  }, [])

  return (
    <div>
      <Menu active={1} />
      <Greeting />
      {order !== undefined && <Order order={order} />}
      <Information toggleChangePassword={toggleChangePassword} />
      <Addresses toggleEditAddress={toggleEditAddress} />
      <Wishlist />
      <Newsletter />
      <Ratings />
    </div>
  )
}

export default Main
