import React, { useEffect, useState } from 'react'
import styles from '../MyAccount.module.scss'
import { getCustomerOrders, setLoadingTrue, setLoadingFalse } from '../../../store/actions'
import { useSelector } from 'react-redux'
import Menu from '../components/menu'
import OrderCard from './components/orderCard'
import Order from './components/order'

type OrdersType = {

}

const Orders: React.FC<OrdersType> = () => {
  const userAuth = useSelector((state: any) => state.userAuth);
  const selectedOrder = useSelector((state: any) => state.myAccount.selectedOrder);

  const [orders, setOrders] = useState([])

  useEffect(() => {

    async function getData() {
      await setLoadingTrue()
      getCustomerOrders(userAuth).then(response => {
        setOrders(response.reverse())
        setLoadingFalse()
      })
    }
    getData();

  }, []);

  
  return (
    <div>
      <Menu active={4} selectedOrder={selectedOrder} />
      {
        (selectedOrder === null) ?
          (orders.length > 0) ?
            <div style={{ width: '100%', backgroundColor: '#fff' }}>
              <div style={{ width: '100%', borderBottom: '2px solid #f8f7f6', padding: '15px', color: '#e8927c' }}>
                {orders?.length} itens encontrados
                </div>
              {orders?.map((order: any, index: number) => {
                return (
                  <div key={index} style={{ borderBottom: '6px solid #f8f7f6', paddingTop: '10px' }}>
                    <OrderCard order={order} />
                  </div>
                )
              })}
            </div> :
            <div style={{ width: '100%', padding: '15px', minHeight: '80vh', backgroundColor: '#fff' }}>
              <p className={styles.primaryColor}>Você ainda não fez nenhum pedido.</p>
            </div> :
          <Order order={orders[orders?.findIndex((x: any) => x.entity_id === selectedOrder)]} />
      }


    </div>
  )
}

export default Orders
