import React from 'react'
import Layout from '../components/Layout/Layout'
import { PageProps } from "gatsby"
import { Router } from "@reach/router"
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute/PrivateRoute'
import Main from '../components/MyAccount/Main/Main'
import Newsletter from '../components/MyAccount/Newsletter/Newsletter'
import Addresses from '../components/MyAccount/Addresses/Addresses'
import MyData from '../components/MyAccount/MyData/MyData'
import Orders from '../components/MyAccount/Orders/Orders'
import Ratings from '../components/MyAccount/Ratings/Ratings'
import Wishlist from '../components/MyAccount/Wishlist/Wishlist'

const minhaConta: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Login" />
      <Router>
        <PrivateRoute path="/minha-conta/" component={Main} />
        <PrivateRoute path="/minha-conta/newsletter/" component={Newsletter} />
        <PrivateRoute path="/minha-conta/enderecos/" component={Addresses} />
        <PrivateRoute path="/minha-conta/dados/" component={MyData} />
        <PrivateRoute path="/minha-conta/pedidos/" component={Orders} />
        <PrivateRoute path="/minha-conta/lista-de-desejos/" component={Wishlist} />
        <PrivateRoute path="/minha-conta/avaliacoes/" component={Ratings} />
      </Router>
    </Layout>
  )
}

export default minhaConta



