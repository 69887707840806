import React, { useState } from 'react'
import styles from '../MyAccount.module.scss'
import { useSelector } from 'react-redux'
import { handleSaveUserData } from '../../../store/actions'
import Menu from '../components/menu'
import DrawerEditAddress from '../../Drawers/DrawerEditAddress/DrawerEditAddress'
import Button from '@material-ui/core/Button'

type AddressesType = {

}

const Addresses: React.FC<AddressesType> = () => {
  const userAuth = useSelector((state: any) => state.userAuth);
  const userData = useSelector((state: any) => state.userData);
  const [editAddress, setEditAddress] = useState({});
  const [savingIn, setSavingIn] = useState("");
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const handleEditAddress = (label: any, value: any) => {
    const copyAddress = { ...editAddress };

    switch (label) {

      case 'street': {
        const copyStreet = [...copyAddress.street]
        copyStreet[0] = value
        copyAddress.street = copyStreet
      }
        break;
      case 'number': {
        const copyStreet = [...copyAddress.street]
        copyStreet[1] = value
        copyAddress.street = copyStreet
      }
        break;
      case 'block': {
        const copyStreet = [...copyAddress.street]
        copyStreet[2] = value
        copyAddress.street = copyStreet
      }
        break;
      case 'complement': {
        const copyStreet = [...copyAddress.street]
        copyStreet[3] = value
        copyAddress.street = copyStreet
      }
        break;
      case 'region':
        copyAddress.region = {
          region_code: value.id,
          region: value.children,
          region_id: parseInt(value.value)
        }
        copyAddress.region_id = parseInt(value.value)
        break;
      default:
        copyAddress[label] = value
        break;
    }
    setEditAddress(copyAddress)
  }
  const handleOpenEditAddress = (savingReference: any) => {
    if (savingReference !== savingIn) {
      setOpenEditAddress(true);
      setSavingIn(savingReference);
      if (savingReference === "billing") {
        setEditAddress(JSON.parse(JSON.stringify(userData.addresses.find((x: any) => x.default_billing === true))))
      } else {
        setEditAddress(JSON.parse(JSON.stringify(userData.addresses.find((x: any) => x.default_shipping === true))))
      }
    } else {
      setOpenEditAddress(false);
      setSavingIn("");
      setEditAddress({})
    }
  }
  const saveEditAddress = () => {
    const copyUserData = JSON.parse(JSON.stringify(userData))
    if (savingIn === "billing") {
      const billingIdx = copyUserData.addresses.findIndex((x: any) => x.default_billing === true)
      copyUserData.addresses[billingIdx] = editAddress;
    } else if (savingIn === "shipping") {
      const shippingIdx = copyUserData.addresses.findIndex((x: any) => x.default_shipping === true)
      copyUserData.addresses[shippingIdx] = editAddress;
    }
    handleSaveUserData(userAuth, copyUserData);
    handleOpenEditAddress(savingIn);
  }
  return (
    <div>
      <Menu active={3} />
      <div style={{ minHeight: '90vh', backgroundColor: '#fff' }}>
        {userData.addresses.map((address: any, index: any) => {
          if (address.default_billing === true) {
            return (
              <div key={index} style={{ borderBottom: '2px solid #f8f7f6', padding: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span className={styles.subtitles}>Endereço de cobrança</span>
                  <Button
                    disableTouchRipple
                    onClick={() => { handleOpenEditAddress('billing') }}
                    variant='text'
                    color='primary'
                    size='small'
                    className={styles.padding0}
                    classes={{ label: styles.labelButton }}
                  >
                    Editar
                  </Button>
                </div>
                <span>{`${address.firstname} ${address.lastname}`}</span> <br />
                {
                  address.street[3] ?
                    <span>{`${address.street[0]}, ${address.street[1]}, ${address.street[3]}, ${address.street[2]}`}</span> :
                    <span>{`${address.street[0]}, ${address.street[1]}, ${address.street[2]}`}</span>
                }
                <br />
                <span>{`${address.city}, ${address.region.region}`}</span> <br />
                <span>{`${address.postcode}`}</span> <br />
                <span>{`${address.telephone}`}</span> <br />
              </div>
            )
          } else if (address.default_shipping === true) {
            return (
              <div key={index} style={{ paddingTop: '30px', borderBottom: '2px solid #f8f7f6', padding: '15px' }}>
                <div  style={{display: 'flex', justifyContent: 'space-between'}}>
                <span className={styles.subtitles}>Endereço de envio</span>
                <Button
                  disableTouchRipple
                  onClick={() => { handleOpenEditAddress('shipping') }}
                  variant='text'
                  color='primary'
                  size='small'
                  className={styles.padding0}
                  classes={{ label: styles.labelButton }}
                >
                  Editar
                </Button>
                </div>
                <span>{`${address.firstname} ${address.lastname}`}</span> <br />
                {
                  address.street[3] ?
                    <span>{`${address.street[0]}, ${address.street[1]}, ${address.street[3]}, ${address.street[2]}`}</span> :
                    <span>{`${address.street[0]}, ${address.street[1]}, ${address.street[2]}`}</span>
                }
                <br />
                <span>{`${address.city}, ${address.region.region}`}</span> <br />
                <span>{`${address.postcode}`}</span> <br />
                <span>{`${address.telephone}`}</span> <br />
              </div>
            )
          }
        })}
      </div>
      <DrawerEditAddress
        openEditAddress={openEditAddress}
        savingIn={savingIn}
        editAddress={editAddress}
        handleOpenEditAddress={handleOpenEditAddress}
        handleEditAddress={handleEditAddress}
        saveEditAddress={saveEditAddress}
      />
    </div>
  )
}

export default Addresses
