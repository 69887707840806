import React from 'react'
import styles from '../../MyAccount.module.scss'
import { Link } from 'gatsby'
import ImageComponent from '../../../ImageComponent/SingleImageComponent'
import RatingStars from '../../../RatingStars/RatingStars'
import Button from '@material-ui/core/Button'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { removeFromWishlist } from '../../../../store/actions'
import { useSelector } from 'react-redux'
import { MICROSERVICE_WISHLIST } from '../../../../../userglobals'
import axios from 'axios'

type productCardType = {
   product: any
   handleDeleteFromWishlist: any
}

const productCard: React.FC<productCardType> = ({ product, handleDeleteFromWishlist }) => {
   const userAuth = useSelector((state: any) => state.userAuth);

   console.log(product)
   return (
      <div style={{ width: '49%', margin: '0.5%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#fff' }}>
         <Link to={`/${product.product.url_key}/`}>
            <div style={{ borderBottom: '2px solid #f8f7f6' }}>
               <ImageComponent
                  altImg={product.product.name}
                  imgUrl={product.product.thumbnail/* + "?width=235&auto=webp"*/}
               />
            </div>
         </Link>
         <div style={{ display: 'flex', padding: '5px 5px 0' }}>
            <div style={{ width: '90%', lineHeight: '15px', paddingRight: '3px' }}>
               <Link to={`/${product.product.url_key}/`} style={{ textDecoration: 'none', color: '#595959' }}>
                  <span style={{ fontSize: '12px' }}>{product.product.name}</span> <br />
                  <span style={{ fontSize: '10px' }}>CÓD.: {product.product.sku}</span>
               </Link>
            </div>
            <div onClick={() => {
               if (userAuth.auth === true) {
                  axios.post(MICROSERVICE_WISHLIST + "delete-wishlist", { ...userAuth, productId: parseInt(product.product_id) })
                     .then(res => {
                        console.log(res.data)
                        removeFromWishlist(parseInt(product.product_id));
                        handleDeleteFromWishlist(product.product_id);
                     })
                     .catch(err => {
                        console.log(err)
                     })
               } else {
                  removeFromWishlist(parseInt(product.product_id));
               }
            }} style={{ width: '10%', display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
               <CloseRoundedIcon />
            </div>
         </div>
         <div style={{ maxHeight: '50px', padding: '0 5px' }}>
            <RatingStars />
         </div>
         <div style={{padding: '0 5px'}}>
            <span style={{ fontSize: '10px', color: '#c2c2c2', textDecoration: 'line-through' }}>DE: R${`${(+product.product.price).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}</span><br />
            <span style={{ fontWeight: 'bold', color: '#e8927c' }}>Por: R${`${(+product.product.min_price).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}</span>
         </div>
         <div className={styles.divButtonBuy}>
            <Button
               disableTouchRipple
               className={styles.buttonBuy}
               component={Link}
               to={`/${product.product.url_key}/`}
               variant="contained"
               fullWidth
            >
               Comprar
               </Button>
         </div>
      </div>
   )
}

export default productCard
